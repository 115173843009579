import { APIRoutes } from "../consts/routes/api-routes";
import API from "./base-service";

export const hospitalApply = async body => {
    try {
        const result = await API.post(APIRoutes.HospitalsApply, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (err) {
        return null;
    }
}

export const expertApply = async body => {
    try {
        const result = await API.post(APIRoutes.ExpertsApply, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result;
    } catch (err) {
        return null;
    }
}

export const expertEdit = async body => {
    try {
        const result = await API.put(`${APIRoutes.ExpertsEdit}/${body.id}`, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result;
    } catch (error) {
        return null;
    }
}

export const trainerApply = async body => {
    try {
        const result = await API.post(APIRoutes.TrainersApply, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result;
    } catch (err) {
        return null;
    }
}

export const verifyCaptcha = async (body) => {
    try {
        const result = await API.post(APIRoutes.VerifyCaptcha, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (error) {
        return null;
    }
}

export const getExpertByEmailHash = async hash => {
    try {
        const result = await API.get(`${APIRoutes.GetExpertByEmailHash}/${hash}`);
        return result.data;
    } catch (error) {
        return null;
    }
}

export const getExpertResume = async id => {
    try {
        const result = await API.get(`${APIRoutes.GetExpertResume}/${id}`);
        return result.data;
    } catch (error) {
        return null;
    }
}

export const getExpertEndorsment = async id => {
    try {
        const result = await API.get(`${APIRoutes.GetExpertEndorsment}/${id}`);
        return result.data;
    } catch (error) {
        return null;
    }
}

export const GetTweets = async () => {
    try {
        const result = await API.get(APIRoutes.Tweets);
        return result;
    } catch (error) {
        return null;
    }
}

export const getExpertEmail = async id => {
    try{
        const result = await API.get(`${APIRoutes.GetExpertEmail}/${id}`)
        return result.data;
    }catch(error){
        return null;
    }
}

export const getExpertPin = async id => {
    try{
        const result = await API.get(`${APIRoutes.GetExpertPin}/${id}`)
        return result.data;
    }catch(error){
        return 'An error occured.'
    }
}

export const GetFile = async(file) => {
    try{
        const response = await API.get(`${APIRoutes.GetFile}/${file}`, { responseType: 'blob' });
        console.log(" Response Headers:", response.headers);
        console.log("Detected Content-Type:", response.headers['content-type']);
        return response;
    } catch(error){
        return 'An error occured';
    }
}

export const getHospitalEmail = async id => {
    try{
        const result = await API.get(`${APIRoutes.GetHospitalEmail}/${id}`);
        return result.data;
    }catch(error){
        return 'An error occured while retrieving hospital email.'
    }
}

export const getHospitalPin = async id => {
    try {
        const result = await API.get(`${APIRoutes.GetHospitalPin}/${id}`);
        return result.data
    } catch (error) {
        return 'An error occured while retrieving hospital pin.'
    }
}

export const getHospitalByHash = async hash => {
    try{
        const result = await API.get(`${APIRoutes.GetHospitalByHash}/${hash}`);
        return result.data;
    } catch(error){
        return null;
    }
}

export const verifyHospital = async body => {
    try {
        const result = await API.post(APIRoutes.VerifyHospital, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}