import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import news1 from '../../media/rsz1.png';
import news2 from '../../media/rsz2.png';
import news3 from '../../media/rsz3.jpg';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: 'Successful prophylaxis in orthopedic surgery intervention in Armenia',
        imgPath: news1,
        description: 'A senior clinical microbiologist visit to Armenian hospital leads to an important outcome of reducing antibiotic prophylaxis for orthopaedic surgery from 5 days to a single day. He presented the evidence to a large group of orthopedic surgeons who afterwards changed their practice.'
    },
    {
        label: 'AMS Masterclass helps setup hospital Antimicrobial Stewardship program',
        imgPath: news2,
        description: 'Dr Christian Lanckohr and his team from the University Hospital Muenster, Germany have managed to establish a sustainable AMS program in their hospital following the AMS Masterclass & expert consultancy in 2019. With the attention on AMS being critically important part of patient care and a matter of patient safety, the hospital established a functional AMS-team with hospital-wide authority and dedicated hours. With the expert consultancy support, the team established regular AMS-rounds into Orthopedics, Oncology and Cardiac Surgery departments, yeilding results in reduced surgical prophylaxis. This showcase of the importance of consultative multi-disciplinary approach in dealing with appropriate antibiotic use serves as an example for other hospitals in the region, including ambulatory settings, through their „AMS Network“.'
    },
    {
        label: 'PAHO project to analyze the infection care system in Surinam',
        imgPath: news3,
        description: ' Prof Heiman Wertheim lead a PAHO mission in Surinam to analyze the infection care system. Suriam is a relatively small country and therefore it is a huge challenge to maintain enough people trained for infection control, clinical microbiology and antimicrobial treatment. An important recommendation was to organize this community in a society (VMS) and initiate a national infection committee and antibiotic policy committee. Considering the challenge of getting reagents for microbiology testing in Surinam It was recommended to install a malditof system in the AZP microbiology lab. This is currently completed. Furthermore a second person is now in training to become a  clinical microbiologist.'
    }
];

const NewsCarousel = () => { //change component name to FeaturedStoryCarousel
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
    <StyledEngineProvider injectFirst>
        <Box className="featured-story-container" >
            <Typography  variant="h4" color="text.secondary" textAlign='center' my={1.5}>
                Local Impact
            </Typography>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Card >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="450"
                                        image={step.imgPath}
                                        alt={step.label}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
                                            {step.label}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" textAlign={'justify'}>
                                            {step.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
        </StyledEngineProvider>
    );
}

export default NewsCarousel;
