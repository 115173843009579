import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Snackbar, Radio, RadioGroup, FormControlLabel, Modal, Box, Alert, TextField, Fade, Backdrop } from "@mui/material";
import { getHospitalByHash, verifyHospital } from "../../services/apply-service";
import { updateExpertMatchStatus } from "../../services/matches-service";
import { getHospitalEmail, getHospitalPin } from "../../services/apply-service";

const ExpertSelection = () => {
    const [selectedExpert, setSelectedExpert] = useState(null);
    const [selectedExpertName, setSelectedExpertName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [emailHash, setEmailHash] = useState(false);
    const [experts, setExperts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [hospitalId, setHospitalId] = useState('');
    const [hospitalName, setHospitalName] = useState('');
    const [authModalOpen, setAuthModalOpen] = useState(true);
    const [enteredEmail, setEnteredEmail] = useState("");
    const [enteredPin, setEnteredPin] = useState("");
    const [validationError, setValidationError] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        borderRadius: "12px",
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        const hash = new URLSearchParams(window.location.search).get('h');
        setEmailHash(hash);
    }, [])
    const handleSelect = (event) => {
        setSelectedExpert(event.target.value);
        const expert = experts.find(x => x.id === event.target.value || x.id == event.target.value);
        setSelectedExpertName(!expert ? '' : `${expert.name} ${expert.surname}`);
    };


    const handleConfirmModal = async () => {
        setModalOpen(false);
        updateExpertMatchStatus({
            hospitals_applications_id: hospitalId,
            hospital_name: hospitalName,
            experts_applications_id: selectedExpert,
            expert_name: selectedExpertName
        }).then(res => {
            if (res.status === 200) {
                setIsSuccess(true);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setSuccessMessage("Thank you. Your expert selection was succesfull. You will be contacted by our administrators for the following steps.");
                setTimeout(() => {
                    window.location.replace('/');
                }, [7500]);

            }
        })

        setSnackbarOpen(true);
    };

    const handleVerify = async () => {
        try {
            verifyHospital({ hash: emailHash, email: enteredEmail, pin: enteredPin }).then(res => {
                if (res.success) {
                    setAuthModalOpen(false);
                    setValidationError("");
                    getHospitalByHash(emailHash).then(res => {
                        setExperts(res.experts);
                        setHospitalId(res.hospital_id);
                        setHospitalName(res.hospital_name);
                    }).catch(er => console.log(er));
                } else setValidationError("Invalid email or PIN. Please try again.");
            })
        } catch (error) {
            console.log(error);
            setValidationError("Error verifying credentials. Please try again.");
        }
    };


    const handleSubmit = () => {
        if (selectedExpert) {
            setModalOpen(true);
        }
    };
    const renderList = (items, formatter) => (
        items?.filter(Boolean).map((item, index) => (
            <div key={index}>{formatter(item)}</div>
        ))
    );
    return (
        <>
            {isSuccess ? (
                <div className="success-msg-div-bea">
                    <Alert className="success-msg-alert" severity="success">
                        <strong><h3>{successMessage}</h3></strong>
                    </Alert>
                </div>
            ) : (
                <div style={{
                    maxWidth: 900, margin: "auto", padding: 20, filter: authModalOpen ? "blur(4px)" : "none",
                    pointerEvents: authModalOpen ? "none" : "auto"
                }}>
                    <Typography variant="h4" style={{ marginBottom: 20, color: "#106680" }}>
                        Select an Expert
                    </Typography>
                    <RadioGroup value={selectedExpert} onChange={handleSelect}>
                        {experts.map((expert) => (
                            <Card key={expert.id} style={{ marginBottom: 10, backgroundColor: "#F5F5F5", width: "100%" }}>
                                <CardContent>
                                    <Typography variant="h6" color="#4AA2C1">
                                        {expert.name} {expert.surname}
                                    </Typography>
                                    <Typography variant="body2"><strong>Institution:</strong> {expert.institution}</Typography>
                                    <Typography variant="body2"><strong>Country:</strong> {expert.country}</Typography>
                                    <Typography variant="body2"><strong>Expertise:</strong> {expert.level_of_expertise}</Typography>

                                    <Typography variant="body2"><strong>Specialties:</strong><br />
                                        {renderList(expert.specialties, s => {
                                            if (s.id === 10) {
                                                return `Surgery (${expert.specialty_surgery_subspecialty || "N/A"})`;
                                            }
                                            return s.value;
                                        })}
                                    </Typography>

                                    <Typography variant="body2"><strong>Languages:</strong><br />
                                        {renderList(expert.language_proficiency, l => `${l.value} (${l.proficiency})`)}
                                        {expert.language_proficiency_other && expert.language_proficiency_other.trim() !== '- None' && (
                                            <div>{expert.language_proficiency_other}</div>
                                        )}
                                    </Typography>


                                    <Typography variant="body2"><strong>Registry Related Experience:</strong><br />
                                        {renderList(expert.registry_related_experience, r => `${r.value} (${r.years_of_experience} years)`)}
                                        {expert.registry_related_experience_other?.trim() && (
                                            <div>{expert.registry_related_experience_other}</div>
                                        )}
                                    </Typography>

                                    <Typography variant="body2"><strong>Regional Experience:</strong><br />
                                        {renderList(expert.regional_experience, r => `${r.value} (${r.years_of_experience} years)`)}
                                        {expert.regional_experience_countries?.trim() && (
                                            <div>{expert.regional_experience_countries}</div>
                                        )}
                                    </Typography>

                                    <Typography variant="body2"><strong>General Availability:</strong> {expert.general_availability}</Typography>
                                    <Typography variant="body2"><strong>Professional Society Membership:</strong> {expert.professional_society_membership}</Typography>

                                    <FormControlLabel
                                        value={`${expert.id.toString()}`}
                                        control={<Radio color="primary" />}
                                        label="Select this expert"
                                    />
                                </CardContent>
                            </Card>
                        ))}
                    </RadioGroup>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!selectedExpert}
                        style={{ backgroundColor: "#106680", color: "white", marginTop: 10 }}
                    >
                        Confirm Selection
                    </Button>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={() => setSnackbarOpen(false)}
                        message={`You selected expert ID: ${selectedExpert}`}
                    />

                    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                        <Box sx={modalStyle}>
                            <Typography variant="h6" gutterBottom>
                                Confirm Expert Selection
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                You are about to select <strong>{selectedExpertName}</strong> for your requested support
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                By continuing, the remaining suggested experts will be automatically rejected    
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Are you sure you want to proceed?    
                            </Typography>
                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <Button onClick={() => setModalOpen(false)} style={{ marginRight: 10 }}>
                                    Cancel
                                </Button>
                                <Button onClick={handleConfirmModal} variant="contained" color="primary">
                                    Confirm
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

                    <Modal
                        open={authModalOpen}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{ timeout: 500 }}
                    >
                        <Fade in={authModalOpen}>
                            <Box sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 2,
                                width: "400px",
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}>
                                <Typography variant="h6" align="center">Authentication Required</Typography>
                                <TextField
                                    label="Email"
                                    fullWidth
                                    value={enteredEmail}
                                    onChange={(e) => setEnteredEmail(e.target.value)}
                                    onKeyDown={e => e.key === 'Enter' ? handleVerify() : null}
                                />
                                <TextField
                                    label="PIN"
                                    type="number"
                                    fullWidth
                                    value={enteredPin}
                                    onChange={(e) => setEnteredPin(e.target.value)}
                                    onKeyDown={e => e.key === 'Enter' ? handleVerify() : null}
                                />
                                {validationError && <Alert severity="error">{validationError}</Alert>}
                                <Button variant="contained" color="primary" onClick={handleVerify}>
                                    Send
                                </Button>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default ExpertSelection;