import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppRoutes } from "../../consts/routes/app-routes";
import './what-is-spice-expert.css';

const WhatIsSpiceExpertComponent = () => {
    return (
        <>
            <div class="content-wse">
                <p>
                    A SPICE expert is any professional who is motivated to exchange knowledge and experience with their peers in resource-limited settings to contribute to improved infectious disease care systems.
                    You will become part of a global network of infectious disease specialists, microbiologists, behavior change experts, and AMS advocates who are passionate about building local capacity to address pressing issues in the field of infectious disease care.
                    Experts will be matched with an LMIC-partner after a careful admission and vetting procedure, and you will have the option of providing online or on-site support.
                </p>
                <p>Below, you will find answers to the most commonly asked questions.</p>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>Why should I become an expert?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Becoming a part of the SPICE registry means that you will be able to make a direct and meaningful contribution to the improvement of the quality of care at your host institution. By sharing your expertise, you will contribute to local capacity and knowledge building, fostering sustainable change. Previous missions have been highly effective in transferring knowledge to LMIC institutions, which translated directly into better practice. Working as a SPICE expert, you will get an opportunity to work cross-culturally, learn from your peers and gain personal and professional experience.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>Who can become a SPICE expert?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Any professional with experience in and affinity with infectious disease management, antimicrobial stewardship, behavior change or similar sectors.
                        </p>
                        <p>
                            Enthusiastic to share their knowledge and experience with colleagues and peers from resource-limited settings.
                        </p>
                        <p>
                            Experience working in diverse contexts – preferably proven professional experience in a specific region or country.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>How does it work?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Once you have registered your interest with us <a href={AppRoutes.ExpertApply}>here</a>, we will review your application and start the admission and vetting process. If successful, your information will be entered into the registry and your onboarding, orientation and matching procedure will start. Usually, each host institution is assigned one expert consultant to work with them throughout the idea development, planning and implementation, over a period of six months to one year.
                        </p>
                        <p>
                            You have the option of sharing your expertise and knowledge through:
                        </p>
                        <ul>
                            <li>
                                Online support, providing mentoring and advice through regular video meetings
                            </li>
                            <li>
                                On-site support, providing advice and supervision during missions to the host institution
                            </li>
                            <li>
                                Combination of online and on-site support, providing consistent mentoring, advice and supervision
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>What do we offer?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Any costs associated with the travel of the expert to the facility (travel, subsistence, accommodation, visa), will be covered by the SPICE program, based on prior approval. As SPICE has a social mission, experts are not entitled to remuneration or expert fee. Successful completion of expert consultancy will be awarded with a SPICE expert certificate, formal acknowledgement of the provided support by SPICE registry and the host institution, as well as featured story on the SPICE website (with agreement from the expert and host)
                        </p>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default WhatIsSpiceExpertComponent;