import { APIRoutes } from "../consts/routes/api-routes";
import API from "./base-service";

export const addMatch = async body => {
    try {
        const result = await API.post(APIRoutes.AddMatch, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateMatchStatus = async (id, body) => {
    try {
        const response = await API.put(`${APIRoutes.UpdateHospitalExpertMatch}/${id}`, body, { headers: { 'Content-Type': 'application/json' } });
        if (response.status === 200) {
            return 'Match status updated succesfully.'
        }
    } catch (error) {
        console.log(error);
        return 'An error occured while updating match status.';
    }
}

export const updateExpertMatchStatus = async (body) => {
    try {
        const response = await API.put(`${APIRoutes.UpdateExpertMatchStatus}`, body, { headers: { 'Content-Type': 'application/json' } });
       return response;
    } catch (error) {
        console.log(error);
        return 'An error occured while updating match status.';
    }
}

export const deleteHospitalExpertMatch = async (id) => {
    try {
        const response = await API.put(`${APIRoutes.DeleteHospitalExpertMatch}/${id}`);
        if (response.status === 200) {
            return response;
        }
    } catch (error) {
        console.log(error);
        return 'An error occured while deleting hospital expert match.'
    }
}