import { useState } from 'react';
import news3 from '../../media/rsz3.jpg';
import { getContentById, getContentBySlug } from '../../services/content-service'
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions, CardHeader } from '@mui/material';
import './story-post.css';

const StoryPost = ({ slug }) => {
    const [data, setData] = useState({});

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getContentBySlug(slug).then((res) => {
            console.log(res);
            setData(res);
        }).catch(error => { console.log(error) })
    }, []);
    
    return (
        <div className='story-post-container-div'>
            <div className='content-wat'>
                <img className='image-from-content' 
                // src={`data:image/png;base64,${data.image}`} 
                src={data.image_url}
                alt={data.title}></img>
                <h2 style={{ textAlign: 'center' }}>{data.title}</h2>
                <h3 style={{ textAlign: 'center' }}>{data.country_region}</h3>
                <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            </div>
        </div>
    )
}

export default StoryPost;