import { env } from "../helpers/env";

export const APIRoutes = { 
    BASE:  env === 'PROD' ? 'https://www.spice-global.org/api' : 'http://localhost:5000/api',
    HospitalsApply: '/hospital-applications',
    VerifyCaptcha: '/auth/verify-captcha',
    ContactUs: '/contact/',
    ExpertsApply: '/experts-applications',
    ExpertsEdit: '/experts-applications',
    TrainersApply: '/trainers-applications',
    GetExpertByEmailHash: '/experts-applications/get-by-email-hash',
    GetExpertResume: '/experts-applications/get-expert-resume',
    GetExpertEndorsment: '/experts-applications/get-expert-endorsment',
    Tweets: '/auth/tweets',
    Content: '/content',
    SortByOrder: '/content/homepage-content',
    DeleteContent: '/content/soft-delete',
    GetContentBySlug: 'content/getContentBySlug',
    GetFile: '/experts-applications/getFile',
    GetExpertEmail: "/experts-applications/get-expert-email",
    GetExpertPin: "/experts-applications/get-expert_pin",
    AddMatch: "/matches",
    UpdateHospitalExpertMatch: "/matches",
    DeleteHospitalExpertMatch: "/matches/soft-delete",
    GetHospitalEmail: '/hospital-applications/get-hospital-email',
    GetHospitalPin: '/hospital-applications/get-hospital-pin',
    GetHospitalByHash: '/matches/experts',
    UpdateExpertMatchStatus: '/matches/update-expert-match-status',
    VerifyHospital: '/hospital-applications/authenticate',
};