export const requiredHospitalsApplyFormProps = [
    'name',
    'address',
    'city',
    'country',
    'legal_representative_authorized_person',
    'legal_representative_authorized_person_position',
    'contact_person',
    'contact_person_position',
    'phone_number',
    'email',
    'about_the_hospital',
    'institution_level_id',
    'institution_type_id',
    'field_of_expertise_ids',
    'requested_support_description',
    // 'letter_of_commitment_base64',
    // 'comments_and_additional_info',
    // 'consent',
    'terms_and_conditions',
    'information_accuracy_confirmation'
];

export const requiredExpertsApplyFormProps = [
    'name',
    'surname',
    'institution',
    'phone',
    'email',
    'orcid',
    'researcher_identifier',
    'national_registry_identifier',
    'country',
    'level_of_expertise_id',
    // 'level_of_expertise_other',
    'work_experience',
    'specialty_ids',
    'professional_society_membership',
    // 'specialty_other',
    // 'specialty_surgery_subspecialty',
    'registry_related_experience',
    // 'registry_related_experience_other',
    'regional_experience',
    // 'regional_experience_countries',
    'language_proficiency',
    // 'language_proficiency_other',
    'general_availability',
    // 'general_availability_time',
    // 'general_availability_other',
    'consent',
    'declaration_of_honour'
];

export const requiredTrainersApplyFormProps = [
    'name',
    'surname',
    'email',
    'phone_number',
    'country',
    'field_of_expertise_ids'
];

export const hospitalsApplyFormProps = {
    name: '',
    address: '',
    city: '',
    country: '',
    legal_representative_authorized_person: '',
    legal_representative_authorized_person_position: '',
    contact_person: '',
    contact_person_position: '',
    phone_number: '',
    email: '',
    about_the_hospital: '',
    institution_level_id: null,
    institution_level_other: null,
    institution_type_id: null,
    institution_type_other: null,
    field_of_expertise_ids: null,
    field_of_expertise_other: null,
    requested_support_description: '',
    // letter_of_commitment_base64: null,
    // letter_of_commitment_base64_extension: null,
    comments_and_additional_info: '',
    // consent: false,
    terms_and_conditions: false,
    information_accuracy_confirmation: false
};

export const expertsApplyFormProps = {
    name: '',
    surname: '',
    institution: '',
    phone: '',
    email: '',
    orcid: '',
    researcher_identifier: '',
    national_registry_identifier: '',
    country: '',
    level_of_expertise_id: null,
    level_of_expertise_other: '',
    work_experience: null,
    professional_resume_base64: null,
    professional_resume_base64_extension: null,
    professional_endorsment_base64: null,
    professional_endorsment_base64_extension: null,
    professional_society_membership: '',
    specialty_ids: [],
    specialty_other: '',
    specialty_surgery_subspecialty: '',
    registry_related_experience: [],
    registry_related_experience_other: '',
    regional_experience: [],
    regional_experience_countries: '',
    language_proficiency: [],
    language_proficiency_other: '',
    general_availability: '',
    general_availability_time: '',
    general_availability_other: '',
    consent: false,
    declaration_of_honour: false
};

export const trainersApplyFormProps = {
    name: '',
    surname: '',
    email: '',
    phone_number: '',
    country_origin: '',
    country_driveams: null,
    field_of_expertise_ids: null,
    language_proficiency: [],
    language_proficiency_other: '',
    is_escmid_course_completed: false,
    is_drive_ams_course_completed: false
};

export const availabilityValues = { onlineOnly: false, onsiteOnly: false, onlineOnsite: false, other: '' };

export const languageMarksValues = [{ value: 0, label: 'None' }, { value: 1, label: 'Basic' }, { value: 2, label: 'Intermediate' }, { value: 3, label: 'Expert' }, { value: 4, label: 'Native' }];

export const languagesValues = [   
        {id: 0, value: 'Other'},     
        { id: 1, value: 'English' },
        { id: 2, value: 'French' },
        { id: 3, value: 'German' },
        { id: 4, value: 'Russian' },
        { id: 5, value: 'Spanish' },
        { id: 6, value: 'Italian' }
];

export const proficienyValues = {English: 0, French: 0, German: 0, Russian: 0, Spanish: 0, Italian: 0, Other: 0};

export const levelOfExpertiseValues = [{ id: 1, value: 'Specialist / clinical', }, { id: 2, value: 'Academic / non-clinical' }];

export const regionalExperienceValues = [
    {
        id: 1,
        value: 'Africa'
    },
    {
        id: 2,
        value: 'Eastern-Mediterranean region'
    },
    {
        id: 3,
        value: 'Europe'
    },
    {
        id: 4,
        value: 'South-East Asia'
    },
    {
        id: 5,
        value: 'Americas (PAHO)'
    },
    {
        id: 6,
        value: 'Western Pacific'
    }
];

export const registryRelatedExperienceValues = [
    {
        id: 1,
        value: 'Infectious disease management'
    },
    {
        id: 2,
        value: 'Microbiology and diagnostics'
    },
    {
        id: 3,
        value: 'Infection prevention and control'
    },
    {
        id: 4,
        value: 'Antimicrobial Stewardship'
    },
    {
        id: 5,
        value: 'Implementation science and behavior change'
    }
];

export const specialtyFieldsValues = [
    {
        id: 1,
        value: 'Infectious diseases management'
    },
    {
        id: 2,
        value: 'Clinical microbiology'
    },
    {
        id: 3,
        value: 'Infection control'
    },
    {
        id: 4,
        value: 'Intensive care'
    },
    {
        id: 5,
        value: 'Clinical pharmacy'
    },
    {
        id: 6,
        value: 'Pharmacology'
    },
    {
        id: 7,
        value: 'Nursing'
    },
    {
        id: 8,
        value: 'Pediatrics'
    },
    {
        id: 9,
        value: 'Pulmonology'
    },
    {
        id: 10,
        value: 'Surgery (please specify subspeciality):'
    }, //ОВА ДА СЕ ИСХЕНДЛА
    {
        id: 11,
        value: 'Internal medicine'
    },
    {
        id: 12,
        value: 'Laboratory – microbiology'
    },
    {
        id: 13,
        value: 'Laboratory – pathology'
    },
    {
        id: 14,
        value: 'Public health'
    },
    {
        id: 15,
        value: 'General/family medicine'
    },
    {
        id: 16,
        value: 'Veterinary sciences'
    },
    {
        id: 17,
        value: 'Implementation science'
    },
    {
        id: 18,
        value: 'Social sciences (anthropology, social care, etc.)'
    },
    {
        id: 19,
        value: 'Psychology'
    },
    {
        id: 20,
        value: 'Economics and related sciences'
    }
]

export const fieldsOfExpertise = [
    {
        value: 1,
        label: 'Infectious diseases management'
    },
    {
        value: 2,
        label: 'Clinical microbiology'
    },
    {
        value: 3,
        label: 'Diagnostic stewardship and laboratory services'
    },
    {
        value: 4,
        label: 'Infection prevention and control'
    },
    {
        value: 5,
        label: 'Antimicrobial Stewardship'
    }
];