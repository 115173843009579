export const customHasher = (str, iterations = 3) => {
    let hash = 0;
    if (str.length === 0) return hash;
    let result;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0;
    }
    
    const salt = "asaltisrandomdatafedasanadditionalinputtoaone-wayfunctionthathashesdata"; 
    
    result = Math.abs(hash + salt.length).toString(36);
    result += Math.abs((hash+salt.length) * 31).toString(36);
    result += Math.abs((hash + salt.length) ^ 0xabcdef).toString(36); 
    
    for (let i = 1; i < iterations; i++) {
        hash = (hash * 31 + i) | 0; 
        result += Math.abs(hash).toString(36);
    }
    
    return result;
};