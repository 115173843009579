import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, Slider, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import axios from 'axios';
import './become-trainer-apply.css';
import { requiredTrainersApplyFormProps, trainersApplyFormProps } from '../../consts/form-helpers/form-consts';
import { languageMarksValues, languagesValues, proficienyValues, fieldsOfExpertise } from "../../consts/form-helpers/form-consts";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { trainerApply, verifyCaptcha } from "../../services/apply-service";

const BecomeTrainerApplyComponent = () => {
    const HCAPTCHA_KEY = 'd2169502-dac0-4a8c-b8c0-18b4da968a86';
    const [countryOptions, setCountryOptions] = useState([]);
    const [emptyFormProps, setEmptyFormProps] = useState([]);
    const [formData, setFormData] = useState({
        ...trainersApplyFormProps,
        // is_escmid_course_completed: false,
        // is_drive_ams_course_completed: false
    });
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedFields, setSelectedFields] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [countryOfOrigin, setCountryOfOrigin] = useState('');
    const [proficiency, setProficiency] = useState({ ...proficienyValues });
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    const languageMarks = [...languageMarksValues];
    const languages = [...languagesValues];

    const handleEmailBlur = () => { setEmailError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ? 'Invalid email format' : '') };

    const handleCaptchaVerify = token => {
        setError(null);
        setCaptchaToken(token);
    };

    const handleFieldChange = (e) => {
        setError(null);
        setEmptyFormProps((props) => props.filter(prop => prop !== 'field_of_expertise_ids'));

        if (e.target.value.length <= 3) {
            setSelectedFields(e.target.value);
            setFormData({ ...formData, field_of_expertise_ids: e.target.value })
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setError(null);

        axios.get('https://restcountries.com/v3.1/all')
            .then((response) => {
                const newCountries = response.data.map(country => ({
                    label: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    value: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    flag: country.flags.png
                }));

                newCountries.sort((a, b) => a.label.localeCompare(b.label));

                setCountryOptions(newCountries);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleInputChange = e => {
        setIsOtherSelected(e.target.value === 'Other');

        setError(null);
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (emptyFormProps.includes(e.target.name)) {
            setEmptyFormProps((props) => props.filter(prop => prop !== e.target.name));
        }
    };

    const validateForm = () => {
        let isFormValid = true;

        for (let prop of requiredTrainersApplyFormProps) {
            if (formData[prop] === '' || formData[prop] === null || formData[prop] === false) {
                setEmptyFormProps((prev) => [...prev, prop]);
                setError('Please fill out all required fields');
                isFormValid = false;
            }
        }

        return isFormValid;
    }

    const handleSubmit = async () => {
        if (formData.language_proficiency_other !== '' || formData.language_proficiency_other !== null) {
            formData.language_proficiency_other = `${formData.language_proficiency_other} - ${languageMarks.find(x => x.value === proficiency.Other).label}`;
        };

        if (validateForm()) {
            trainerApply(formData).then(res => {
                if (res && res.status === 201) {
                    setIsSuccess(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }).catch(er => {
                setError("An error occurred while submitting your application. Please try again.");
            })
        } else setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 1000);
    };

    const handleSliderChange = (language) => (e, value) => {
        if (language !== 'Other') {
            setProficiency({ ...proficiency, [language]: value });

            if (value !== 0) {
                const entry = {
                    id: languages.find(l => l.value === language).id,
                    value: language,
                    proficiency: languageMarks.find(x => x.value === value).label
                };

                setFormData({
                    ...formData,
                    language_proficiency: [
                        ...(formData.language_proficiency?.filter(item => item.value !== language) || []), entry
                    ]
                });

                if (emptyFormProps.includes('language_proficiency')) {
                    setEmptyFormProps((props) => props.filter(prop => prop !== 'language_proficiency'));
                }
            } else {
                if (formData.language_proficiency.some(x => x.value === language)) {
                    const updatedLanguageProficiency = formData.language_proficiency.filter(x => x.value !== language);
                    setFormData({ ...formData, language_proficiency: [...updatedLanguageProficiency] });
                }
            }
        } else {
            setProficiency({ ...proficiency, [language]: value });
        }
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked, // Ensure boolean values are set
        });
    };

    return (
        <>
            {isSuccess ? (
                <div className='success-message-bta'>
                    <Alert className='success-msg-bta-alert' severity="success">
                        <strong><h3>Thank you. Your application was successfully sent. You will be contacted for further details.</h3></strong>
                    </Alert>
                </div>) : (
                <>
                    <Typography variant="h4" color={'primary'} m={2.5} textAlign={'center'}>Trainers Registry</Typography>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className='content-bta-form'
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Name"
                                            id="name"
                                            name='name'
                                            value={formData.name || ""}
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('name') ? 'This field is required' : ''}
                                            error={emptyFormProps.includes('name')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Surname"
                                            id="surname"
                                            name='surname'
                                            value={formData.surname || ""}
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('surname') ? 'This field is required' : ''}
                                            error={emptyFormProps.includes('surname')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Email"
                                            id="email"
                                            name='email'
                                            type="email"
                                            value={formData.email || ""}
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            onBlur={handleEmailBlur}
                                            helperText={emailError || (emptyFormProps.includes('email') ? 'This field is required' : '')}
                                            error={!!emailError || emptyFormProps.includes('email')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Phone number"
                                            name="phone_number"
                                            value={formData.phone_number || ""}
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('phone_number') ? 'This field is required' : ''}
                                            error={emptyFormProps.includes('phone_number')}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id='outlined-select-country-of-origin'
                                            select
                                            label="Country of Origin"
                                            name="country_origin"
                                            size="small"
                                            fullWidth
                                            onChange={handleInputChange}
                                        >
                                            {countryOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    <img loading="lazy" width="20" src={option.flag} alt="" style={{ marginRight: '8px' }} />
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={!!emptyFormProps.includes('field_of_expertise_ids')}>
                                            <InputLabel id="field-of-expertise-label">Field of Expertise</InputLabel>
                                            <Select
                                                labelId="field-of-expertise-label"
                                                id="field-of-expertise"
                                                multiple
                                                value={selectedFields}
                                                onChange={handleFieldChange}
                                                renderValue={(selected) => selected.map(value => {
                                                    const option = fieldsOfExpertise.find(opt => opt.value === value);
                                                    return option ? option.label : '';
                                                }).join(', ')}
                                                size="large"
                                            >
                                                {fieldsOfExpertise.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        <Checkbox checked={selectedFields.indexOf(option.value) > -1} />
                                                        <ListItemText primary={option.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {emptyFormProps.includes('field_of_expertise_ids') && (<FormHelperText>This field is required</FormHelperText>)}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="h5" sx={{ marginTop: '3rem' }} gutterBottom>
                                            Language proficiency (multiple answers possible):
                                        </Typography>
                                        {emptyFormProps.includes('language_proficiency') && (
                                            <FormHelperText sx={{ color: '#d32f2f' }}>
                                                <b>This field is required</b>
                                            </FormHelperText>
                                        )}
                                        <Table>
                                            <TableBody>
                                                {languages.map(lang => (
                                                    lang.value !== 'Other' && (
                                                        <TableRow key={lang.id} sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                                            <TableCell>{lang.value}</TableCell>
                                                            <TableCell>
                                                                <Slider
                                                                    value={proficiency[lang.value] || 0}
                                                                    onChange={handleSliderChange(lang.value)}
                                                                    step={1}
                                                                    marks={languageMarks}
                                                                    min={0}
                                                                    max={4}
                                                                    valueLabelDisplay="auto"
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                ))}
                                                {/* Special row for "Other" language input */}
                                                <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                                    <TableCell>Other (please specify):</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="language_proficiency_other"
                                                            value={formData.language_proficiency_other}
                                                            onChange={e => handleInputChange(e)}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                        <Slider
                                                            value={proficiency.Other} // Ensure it's always a number
                                                            onChange={handleSliderChange('Other')}
                                                            step={1}
                                                            marks={languageMarks}
                                                            min={0}
                                                            max={4}
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h5" sx={{ marginTop: '3rem' }} gutterBottom>
                                            I have successfully completed the following:
                                        </Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={formData.is_escmid_course_completed || false}
                                                    onChange={handleCheckboxChange}
                                                    name="is_escmid_course_completed" />}
                                            label="ESCMID AMS Certificate program" />
                                        {formData.is_escmid_course_completed && (
                                            <TextField
                                                type='number'
                                                label="Graduation Year"
                                                name="graduation_year_escmid"
                                                size="small"
                                                fullWidth
                                                value={formData.graduation_year_escmid || ''}
                                                onChange={handleInputChange}
                                            />
                                        )}
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={formData.is_drive_ams_course_completed || false}
                                                onChange={handleCheckboxChange}
                                                name="is_drive_ams_course_completed" />}
                                            label="Drive-AMS course" />
                                        {formData.is_drive_ams_course_completed && (
                                            <>
                                                <TextField
                                                    type='number'
                                                    label="Year"
                                                    name="graduation_year_ams_course"
                                                    size="small"
                                                    fullWidth
                                                    value={formData.graduation_year_ams_course || ''}
                                                    onChange={handleInputChange}
                                                    style={{ marginBottom: "10px" }}
                                                />
                                                <TextField
                                                    id='outlined-select-country'
                                                    name="country_driveams"
                                                    select
                                                    label="Country"
                                                    size="small"
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                >
                                                    {countryOptions.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            <img loading="lazy" width="20" src={option.flag} alt="" style={{ marginRight: '8px' }} />
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {error && (
                                            <Alert variant="filled" severity="error">
                                                Error: {error}
                                            </Alert>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box></>
            )}
        </>
    );
}

export default BecomeTrainerApplyComponent;